import React, { useEffect, useState } from 'react';
import './Calculator.css'; // Import the CSS file
import { JtrimsFormula, TtrimsFormula, TtrimsVerticalFormula } from './TrimsCalculation';
import { lineaHorizontalPanelCount, lineaVerticalPanelCount } from './utils/lineaUtils';
import { calculateDuraTotalItemsHorizontal, calculateDuraTotalItemsVertical } from './utils/duraUtils';
import { calculateStretaHorizontalPanelCount, calculateStretaVerticalPanelCount } from './utils/duraStretaUtils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { HorizontalWastageOfPanel, VerticalWastageOfPanel } from './utils/CalculateWastagePanels';
import { VerticalPanelCountInMM, horizontalPanelCountInMM } from './utils/lineaUtilsCalculationInMM';
import FeetInchesToMMConverter from './FeetInchesToMMConverter';
import { BafflehorizontalPanelCountInMM, BaffleverticalPanelCountInMM } from './utils/BaffleUtils';
import { BaffleCapHorizontal, BaffleCapVertical } from './BaffleCapCalculation';
import ShadesDropdown from './ShadesPricing/ShadesDropdown';
import { JtrimsFormulaDura, TtrimsFormulaDura, TtrimsVerticalFormulaDura } from './DuraTrimsCalculation';
import indianStates from './utils/IndianStates';
import BaffleOptions from './ShadesPricing/CategoryDropdownForBaffle';
import DimensionsInput from './UserDimensionsInput';
import OrientationSwitch from './OrientationSwitch';
import axios from 'axios';


const mmToFeet = (mm) => {
  return (mm / 306).toFixed(2); // 1 foot = 304.8 mm
};  

const lineammToFeet = (mm) => {
  return (mm / 304.8).toFixed(2); // 1 foot = 304.8 mm
};

const TtrimsLength = 10;
const TtrimsLengthDura = 12;


const productsConfigInMM = {
  Soffit: {
    name: 'Soffit (3060 x 300mm)',
    panelLength: 3060,
    panelBreadth: 300,
    perPcSqFt: 9.88,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Soffit2: {
    name: 'Soffit (4000 x 300mm)',
    panelLength: 4000,
    panelBreadth: 300,
    perPcSqFt: 12.92,
    JTrimperPcSqFt: 13.12,
    TTrimperPcSqFt: 13.12,
  },
  Duo: {
    name: 'Duo (3060 x 300mm)',
    panelLength: 3060,
    panelBreadth: 300,
    perPcSqFt: 9.88,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Duo2: {
    name: 'Duo (4000 x 300mm)',
    panelLength: 4000,
    panelBreadth: 300,
    perPcSqFt: 12.92,
    JTrimperPcSqFt: 13.12,
    TTrimperPcSqFt: 13.12,
  },
  Vertica: {
    name: 'Vertica (3060 x 210mm)',
    panelLength: 3060,
    panelBreadth: 210,
    perPcSqFt: 6.92,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Louvers: {
    name: 'Louvers (3060 x 225mm)',
    panelLength: 3060,
    panelBreadth: 225,
    perPcSqFt: 7.41,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Louvers2: {
    name: 'Louvers (4000 x 225mm)',
    panelLength: 4000,
    panelBreadth: 225,
    perPcSqFt: 9.69,
    JTrimperPcSqFt: 13.12,
    TTrimperPcSqFt: 13.12,
  },
  Wave: {
    name: 'Wave (3060 x 240mm)',
    panelLength: 3060,
    panelBreadth: 240,
    perPcSqFt: 7.90,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Dome: {
    name: 'Dome (3060 x 245mm)',
    panelLength: 3060,
    panelBreadth: 245,
    // panelBreadthForPrice: 290,
    perPcSqFt: 9.55,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Prism: {
    name: 'Prism (3060 x 228mm)',
    panelLength: 3060,
    panelBreadth: 228,
    // panelBreadthForPrice: 260,
    perPcSqFt: 8.56,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Stripes: {
    name: 'Stripes (3060 x 180mm)',
    panelLength: 3060,
    panelBreadth: 180,
    // panelBreadthForPrice: 215,
    perPcSqFt: 7.08,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Unic: {
    name: 'Unic (3060 x 240mm)',
    panelLength: 3060,
    panelBreadth: 240,
    // panelBreadthForPrice: 270,
    perPcSqFt: 8.89,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Linea: {
    name: 'Linea (3060 x 115mm)',
    panelLength: 3060,
    panelBreadth: 115,
    // panelBreadthForPrice: 150,
    perPcSqFt: 4.94,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Pyramid: {
    name: 'Pyramid (3060 x 120mm)',
    panelLength: 3060,
    panelBreadth: 120,
    // panelBreadthForPrice: 140,
    perPcSqFt: 4.61,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Arch: {
    name: 'Arch (3060 x 150mm)',
    panelLength: 3060,
    panelBreadth: 150,
    // panelBreadthForPrice: 180,
    perPcSqFt: 5.93,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Norma: {
    name: 'Norma (3660 x 300mm)',
    panelLength: 3660,
    panelBreadth: 300,
    perPcSqFt: 11.81,
    JTrimperPcSqFt: 12.005,
    TTrimperPcSqFt: 12.005,
  },
  Streta: {
    name: 'Streta (3660 x 225mm)',
    panelLength: 3660,
    panelBreadth: 225,
    perPcSqFt: 8.86,
    JTrimperPcSqFt: 12.005,
    TTrimperPcSqFt: 12.005,
  },
  Baffle: {
    name: 'Baffle',
    panelLength: 3060,
    panelBreadth: 50,
    perPcSqFt: 10.04
  }
};

const productsConfig = {
  Soffit: {
    name: 'Soffit (3060 x 300mm)',
    panelLengthFt: 10.04, // Length of each soffit panel in feet
    panelBreadth: 1, // Breadth of each soffit panel in feet
  },
  Soffit2: {
    name: 'Soffit (4000 x 300mm)',
    panelLengthFt: 13.12,
    panelBreadth: 1,
  },
  Duo: {
    name: 'Duo (3060 x 300mm)',
    panelLengthFt: 10.04,
    panelBreadth: 1,
  },
  Duo2: {
    name: 'Duo (4000 x 300mm)',
    panelLengthFt: 13.12,
    panelBreadth: 1,
  },
  Vertica: {
    name: 'Vertica (3060 x 210mm)',
    panelLength: 10.04, // Length of each vertica panel in feet
    panelBreadth: 0.69, // Breadth of each vertica panel in feet
  },
  Louvers: {
    name: 'Louvers (3060 x 225mm)',
    panelLength: 10.04, // Length of each louvers panel in feet
    panelBreadth: 0.74, // Breadth of each louvers panel in feet
  },
  Louvers2: {
    name: 'Louvers (4000 x 225mm)',
    panelLength: 13.12,
    panelBreadth: 0.74,
  },
  Wave: {
    name: 'Wave (3060 x 240mm)',
    panelLength: 10.04,
    panelBreadth: 0.79,
  },
  Dome: {
    name: 'Dome (3060 x 245mm)',
    panelLength: 10.04,
    panelBreadth: 0.80,
  },
  Prism: {
    name: 'Prism (3060 x 228mm)',
    panelLength: 10.04,
    panelBreadth: 0.75,
  },
  Stripes: {
    name: 'Stripes (3060 x 180mm)',
    panelLength: 10.04,
    panelBreadth: 0.59,
  },
  Unic: {
    name: 'Unic (3060 x 240mm)',
    panelLength: 10.04,
    panelBreadth: 0.79,
  },
  Linea: {
    name: 'Linea (3060 x 115mm)',
    panelLength: 10.04,
    panelBreadth: 0.38,
  },
  Pyramid: {
    name: 'Pyramid (3060 x 120mm)',
    panelLength: 10.04,
    panelBreadth: 0.39,
  },
  Arch: {
    name: 'Arch (3060 x 150mm)',
    panelLength: 10.04,
    panelBreadth: 0.49,
  },
  Norma: {
    name: 'Norma (3660 x 300mm)',
    panelLengthFt: 12,
    panelBreadth: 1,
  },
  Streta: {
    name: 'Streta (3660 x 225mm)',
    panelLengthFt: 12,
    panelBreadth: 0.74
  },
  Baffle: {
    name: 'Baffle',
    panelLength: 10.04,
    panelBreadth: 0.16
  }
};

const Calculator = () => {
  const [name, setName] = useState(''); // State for Name
  const [city, setCity] = useState(''); // State for City
  const [state, setState] = useState(''); // State for State
  const [phoneNumber, setPhoneNumber] = useState(''); // State for Phone Number
  const [length, setLength] = useState('');
  const [breadth, setBreadth] = useState('');
  const [product, setProduct] = useState('');
  const [unit, setUnit] = useState('MM');
  const [originalLength, setOriginalLength] = useState('');
  const [originalBreadth, setOriginalBreadth] = useState('');
  const [orientation, setOrientation] = useState('');
  const [result, setResult] = useState(null);
  const [selectedProductDimensions, setSelectedProductDimensions] = useState({});
  const [wastage, setWastage] = useState({ totalWastage: 0 });
  const [baffleSpacing, setBaffleSpacing] = useState('');
  const [panelCount, setPanelCount] = useState(0);
  // const [panelCountForPrice, setPanelCountForPrice] = useState(0);
  const [selectedShade, setSelectedShade] = useState(''); // State for selected shade
  const [selectedCategory, setSelectedCategory] = useState(''); //State for selected category in Baffle
  // const [selectedBottomShade, setSelectedBottomShade] = useState('');
  const [estimatedPricePanels, setEstimatedPricePanels] = useState(0); // State for estimated price
  const [estimatedPriceJtrims, setEstimatedPriceJtrims] = useState(0);
  const [estimatedPriceTtrims, setEstimatedPriceTtrims] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [generatePDF, setGeneratePDF] = useState(false);
 

  var DisplayLengthInFeet = lineammToFeet(parseFloat(length));
  var DisplayBreadthInFeet = lineammToFeet(parseFloat(breadth));



const handleProductChange = (e) => {
  const selectedProduct = e.target.value;
  setProduct(selectedProduct);
  setSelectedShade(null);
  if (selectedProduct) {
    setSelectedProductDimensions(productsConfigInMM[selectedProduct]);
  } else {
    setSelectedProductDimensions({});
    setResult(null);
  }

   // Clear selected category if product is changed
   if (selectedProduct !== 'Baffle') {
    setSelectedCategory('');
  }
};


const handleCalculate = () => {
  // console.log(length);
  // console.log(breadth);
  // Check if any required fields are empty
  if (
    !name || 
    !city || 
    !state || 
    !phoneNumber || 
    !length || 
    !breadth || 
    !product || 
    !orientation || 
    !selectedShade || 
    (product === 'Baffle' && (!selectedCategory || !baffleSpacing)) ||
    parseFloat(length) <= 0 ||  // Check if length is greater than 0
    parseFloat(breadth) <= 0    // Check if breadth is greater than 0
  ) {
    setShowErrorMessage(true); // Show the error message
    return false; // Stop further processing if required fields are empty or invalid
  } else {
    setShowErrorMessage(false); // Hide the error message if all fields are filled

  let panelLength, panelBreadth;


  if (product === 'Soffit' || product === 'Duo') {
    panelLength = productsConfig[product].panelLengthFt;
    panelBreadth = productsConfig[product].panelBreadth;
  } else {
    panelLength = productsConfigInMM[product].panelLength;
    panelBreadth = productsConfigInMM[product].panelBreadth;
    // panelBreadthForPrice = productsConfigInMM[product].panelBreadthForPrice;
  }
    let panelCount = 0;
    let jTrims = 0; // Always 4 corners
    let tTrims = 0;
    let BaffleCapCount = 0;
    // let panelCountForPrice = 0;

   

    // Convert length and breadth from mm to feet
    const lengthInFeet = mmToFeet(parseFloat(length));
    const breadthInFeet = mmToFeet(parseFloat(breadth));

    //Convert Linea length and breadth from mm to feet
    const LinealengthInFeet = lineammToFeet(parseFloat(length));
    const LineabreadthInFeet = lineammToFeet(parseFloat(breadth));

   
    const calculateSoffitAndDuoPanels = (lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
        // if(unit === 'mm'){
          return lineaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation);
        // }else if(unit === 'feet'){
        //   return lineaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth);
        // }
      } else if (orientation === 'Vertical') {
        // if(unit === 'mm'){
          return lineaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }else if(unit === 'feet'){
        //   return lineaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }
      }
    };


    const calculateLineaPanels = (lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
        // if(unit === 'mm'){
          return horizontalPanelCountInMM(lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation);
          
        // }else if(unit === 'feet'){
        //   return lineaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth);
        // }
      } else if (orientation === 'Vertical') {
        // if(unit === 'mm'){
          return VerticalPanelCountInMM(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }else if(unit === 'feet'){
        //   return lineaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }
      }
    };

    const calculateDuraPanels = (length, breadth, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
        return calculateDuraTotalItemsHorizontal(length, breadth, panelLength, panelBreadth);
      } else if (orientation === 'Vertical') { 
        return calculateDuraTotalItemsVertical(breadth, length, panelLength, panelBreadth);  
      }   
    };

    const calculateStretaPanels = (lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
          return calculateStretaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth);
        
      } else if (orientation === 'Vertical') {
         return calculateStretaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
    };
  }
    const calculateBafflePanels = (length, breadth, panelLength, panelBreadth, baffleSpacing, orientation) => {
      if(orientation === 'Horizontal'){
        return BafflehorizontalPanelCountInMM(length,breadth, panelLength, panelBreadth, baffleSpacing, orientation);
      }else if(orientation === 'Vertical'){
        return BaffleverticalPanelCountInMM(length, breadth, panelLength, panelBreadth, baffleSpacing, orientation)
      }
    }

    // const calculateLineaPanelsForPrice = (lengthInFeet, breadthInFeet, panelLength, panelBreadthForPrice, orientation) => {
    //   if (orientation === 'Horizontal') {
    //       console.log(panelBreadthForPrice);

    //       return horizontalPanelCountInMM(lengthInFeet, breadthInFeet, panelLength, panelBreadthForPrice, orientation);
    //   } else if (orientation === 'Vertical') {
    //       return VerticalPanelCountInMM(breadthInFeet, lengthInFeet, panelLength, panelBreadthForPrice);
    //   }
    // };

    // if (product === 'Soffit' || product=== 'Duo' ) { 
    //   panelCount = calculateLineaPanels(LinealengthInFeet, LineabreadthInFeet, panelLength, panelBreadth, orientation )
    // }
    // if(){
    //   panelCount = calculateVerticaAndLouversPanels(lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation);
    // } 
    if(product === 'Soffit' || product === 'Duo'){
      panelCount = calculateSoffitAndDuoPanels(length, breadth, panelLength, panelBreadth, orientation);  
    } 
    else if(product === 'Norma'){
      panelCount = calculateDuraPanels(length, breadth, panelLength, panelBreadth, orientation);
    }
    else if(product === 'Streta'){
      panelCount = calculateStretaPanels(length, breadth, panelLength, panelBreadth, orientation );
    }
    else if(product === 'Baffle'){
      panelCount = calculateBafflePanels(length, breadth, panelLength, panelBreadth, baffleSpacing, orientation);
    }
     else {
      panelCount = calculateLineaPanels(length, breadth, panelLength, panelBreadth, orientation);
      // panelCountForPrice = calculateLineaPanelsForPrice(length, breadth, panelLength, panelBreadthForPrice, orientation );
    }

     // Set the calculated panel count in state
     setPanelCount(Math.ceil(panelCount));
    //  setPanelCountForPrice(Math.ceil(panelCountForPrice));

    // if(unit === 'mm'){
      jTrims = product === 'Baffle' ? 0 : (product === 'Norma' || product === 'Streta') ? JtrimsFormulaDura(lengthInFeet,breadthInFeet) 
      : (product === 'Soffit' || product === 'Duo') ? JtrimsFormula(length, breadth) : JtrimsFormula(lengthInFeet, breadthInFeet);
    // }else{
    //   jTrims = JtrimsFormula(length, breadth);
    // }
    let totalWastage = 0;
    if (orientation === 'Horizontal') {
      if(product === 'Soffit2' || product === 'Duo2' || product === 'Louvers2'){
        tTrims =  lengthInFeet > 13 ? TtrimsFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else if(product === 'Soffit' || product === 'Duo'){
        tTrims = length > 10 ? TtrimsFormula(length, breadth, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else{
        tTrims = product === 'Baffle' ? 0 : lengthInFeet > 10 ? (product === 'Norma' || product === 'Streta') ? TtrimsFormulaDura(LinealengthInFeet, LineabreadthInFeet, TtrimsLengthDura) : 
        TtrimsFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }
      
     
    } else if (orientation === 'Vertical') {
      if(product === 'Soffit2' || product === 'Duo2' || product === 'Louvers2'){
        tTrims =  breadthInFeet > 13 ? TtrimsVerticalFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else if(product === 'Soffit' || product === 'Duo'){
        tTrims = breadth > 10 ? TtrimsVerticalFormula(length, breadth, TtrimsLength) : 0;
        totalWastage = VerticalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else{
        tTrims = product === 'Baffle' ? 0 : breadthInFeet > 10 ? (product === 'Norma' || product === 'Streta') ? TtrimsVerticalFormulaDura(LinealengthInFeet, LineabreadthInFeet, TtrimsLengthDura) :
        TtrimsVerticalFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = VerticalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }
    }

    if(orientation === 'Horizontal'){
      BaffleCapCount = BaffleCapHorizontal(breadth, baffleSpacing);
    }else if(orientation === 'Vertical'){
      BaffleCapCount = BaffleCapVertical(length, baffleSpacing);
    }
    
    // if(product === 'Linea' || product === 'Pyramid'|| product === 'Arch' || product === 'Dome' || product === 'Prism' || product === 'Stripes' || product === 'Unic' ){
    //   const perPcSqFt = productsConfigInMM[product].perPcSqFt;
    //   const totalSqFt = panelCountForPrice * perPcSqFt;
    //   const estimatedPrice = totalSqFt * selectedShade.price;
    //   setEstimatedPricePanels(estimatedPrice);
    // }else{
      const perPcSqFt = productsConfigInMM[product].perPcSqFt;
      const totalSqFt = panelCount * perPcSqFt;
      const estimatedPrice = totalSqFt * selectedShade.price;
      setEstimatedPricePanels(estimatedPrice);
    // }
    

    const JTrimperPcSqFt = productsConfigInMM[product].JTrimperPcSqFt;
    const JTrimtotalSqFt = jTrims * JTrimperPcSqFt;
    const estimatedPriceJtrims = JTrimtotalSqFt * selectedShade.jTrimPrice;
    setEstimatedPriceJtrims(estimatedPriceJtrims);

    const TTrimperPcSqFt = productsConfigInMM[product].TTrimperPcSqFt;
    const TTrimtotalSqFt = tTrims * TTrimperPcSqFt;
    const estimatedPriceTtrims = TTrimtotalSqFt * selectedShade.TtrimPrice;
    setEstimatedPriceTtrims(estimatedPriceTtrims);

    const bottomShadePerPcSqFt = 50;
    const bottomShadeEstimatedPrice = totalSqFt * bottomShadePerPcSqFt;

    const baffleCapPriceCalculation = BaffleCapCount * selectedShade.finishingCapPrice; 
    
    setWastage({ totalWastage });


    setResult({
      panelCount: Math.ceil(panelCount),
      jTrims,
      tTrims: Math.ceil(tTrims),
      productMM: productsConfigInMM[product],
      BaffleCapCount,
      bottomShadeEstimatedPrice,
      baffleCapPriceCalculation
    });

    // Trigger PDF generation after calculation
    setGeneratePDF(true);
  }
  };

  const handleExportToPDF = () => {
    
    let jTrimsName = 'J-Trims Large';
    let tTrimsName = 'T-Trims Large'

    if(product=== 'Soffit' || product=== 'Soffit2' || product === 'Duo' ||  product=== 'Duo2'|| product === 'Louvers' || product === 'Louvers2' || product === 'Norma' || product === 'Streta'){
      jTrimsName = 'J-Trims'
      tTrimsName = 'T-Trims'
    }
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Set a maximum and minimum width for the logo to handle different screen sizes
    const maxLogoWidth = 50; // Maximum width for the logo
    const minLogoWidth = 25; // Minimum width for the logo

    // Calculate the logo width based on the page width (responsive)
    let logoWidth = pageWidth * 0.1; // 10% of the page width
    logoWidth = Math.max(minLogoWidth, Math.min(maxLogoWidth, logoWidth)); // Ensure it's between the max and min values

    const logoHeight = logoWidth; // Maintain aspect ratio by setting height equal to width
    const centerX = (pageWidth - logoWidth) / 2; // Calculate the x position for center alignment

    // Centered top logo
    doc.addImage(companyLogo, 'PNG', centerX, 0, logoWidth, logoHeight); // Adjusted Y position to avoid being too close to the top edge

    // Main Heading ("Quotation")
    doc.setFont('helvetica', 'bold'); // Set font to Helvetica and make it bold
    doc.setFontSize(24);
    doc.setTextColor(81, 81, 81); // Dark slate blue color for heading
    doc.text('QUOTATION', pageWidth / 2, 35, { align: 'center' });

    // Customer Details Table
    const customerDetails = [
      [{ content: 'NAME', styles: { fontStyle: 'bold' } }, name, { content: 'PHONE NUMBER', styles: { fontStyle: 'bold' } }, phoneNumber],
      [{ content: 'CITY', styles: { fontStyle: 'bold' } }, city, { content: 'STATE', styles: { fontStyle: 'bold' } }, state]
    ];

    doc.autoTable({
      startY: 44,  // Adjust the position as needed
      body: customerDetails,
      theme: 'grid',
      styles: {
          fillColor: [255, 255, 255],
          textColor: [81, 81, 81],
          fontStyle: 'normal',
          fontSize: 10,
      },
      columnStyles: {
          0: { cellWidth: 30, halign: 'left' },  // Adjust column widths
          1: { cellWidth: 50, halign: 'left' },
          2: { cellWidth: 50, halign: 'left' },
          3: { cellWidth: 50, halign: 'left' },
      },
      tableWidth: 'wrap',
    });

    const finalY = doc.previousAutoTable.finalY; // The Y position where the last table ends

    // Now add the Orientation and other details below the customer details table

    const lengthBreadthDetails = [
        [`WIDTH (${unit})` , originalLength],
        [`LENGTH (${unit})`, originalBreadth],
        ['ORIENTATION', orientation.toUpperCase()],
    ];

    const imgWidth = 100; // Width of the orientation image
    const imgHeight = 70; // Height of the orientation image
    const margin = 10; // Margin between text and image

    const startYHeading = finalY + 20; // Start position below the customer details

    // Draw the red line to the left of the "Orientation" heading
    const lineHeight = 4; // Adjust the height of the red line
    doc.setDrawColor(197, 22, 28); // Set the color to red
    doc.setLineWidth(1.5); // Set the width of the line
    doc.line(12, startYHeading - 1, 12, startYHeading + lineHeight); // Draw the vertical line closer to the text

    // Add the "Orientation" heading
    doc.setFontSize(14);
    doc.setTextColor(81, 81, 81); // Set the text color for the heading
    doc.text('ORIENTATION', 15, startYHeading + 3); // Adjust the x-coordinate to bring it closer to the line

    // Add the table below the heading
    doc.autoTable({
        startY: startYHeading + 10, // Adjusted to start after the heading
        head: [['PROPERTY', 'VALUE']],
        body: lengthBreadthDetails,
        theme: 'grid',
        styles: { fillColor: [255, 255, 255] },
        headStyles: { fillColor: [114, 114, 114], textColor: [255, 255, 255] }, // Dark slate blue background with white text
        tableWidth: 'wrap',
        margin: { right: imgWidth + margin }, // Right margin to make space for the image
    });

    const finalYOrientation = doc.previousAutoTable.finalY; // The Y position where the last table ends

    // Add the orientation image to the right side of the text details
    if (orientation.toLowerCase() === 'horizontal') {
        doc.addImage(horizontalImage, 'PNG', pageWidth - imgWidth - margin, startYHeading + 2 - 7, imgWidth, imgHeight);
    } else {
        doc.addImage(verticalImage, 'PNG', pageWidth - imgWidth - margin, startYHeading + 2 - 7, imgWidth, imgHeight);
    }

    // Set the Y position for the "Requirements" heading
    const startYRequirements = finalYOrientation + 30;
    const startYRequirementsHeading = startYRequirements - 7;

    // Draw the red line to the left of the "Requirements" heading
    doc.setDrawColor(197, 22, 28); // Set the color to red
    doc.setLineWidth(1.5); // Set the width of the line
    doc.line(12, startYRequirementsHeading - 1, 12, startYRequirementsHeading + lineHeight); // Draw the vertical line closer to the text

    // Add the "Requirements" heading
    doc.setFontSize(14);
    doc.setTextColor(81, 81, 81); // Set the text color for the heading
    doc.text('REQUIREMENTS', 15, startYRequirementsHeading + 3); // Adjust the x-coordinate to bring it closer to the line

    // Add the small paragraph below "Requirements"
    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal'); // Set the font back to normal
    doc.text('Please note the quotation for your requirements shared', 15, startYRequirements + 3); // Add the note below the "Requirements" heading

    let resultsTable = [];
    let totalEstimatedCost = 0;

    // Add the results table
    if (product === 'Baffle') {
      // If the product is Baffle, adjust the data accordingly
      resultsTable = [
        ['1', `${selectedCategory} Top`, selectedShade.name, `${result.panelCount} Panels`, productsConfigInMM[product].perPcSqFt, selectedShade.price, `Rs.${estimatedPricePanels.toFixed(2)}`]
      ];
      totalEstimatedCost = result.panelCount * selectedShade.price; // Calculate total cost for Baffle
    } else {
      // For other products
      resultsTable = [
        ['1', productsConfigInMM[product].name, selectedShade.name, `${result.panelCount} Panels`, productsConfigInMM[product].perPcSqFt, selectedShade.price, `Rs.${estimatedPricePanels.toFixed(2)}`]
      ];
      totalEstimatedCost = result.panelCount * selectedShade.price + estimatedPriceJtrims + (result.tTrims > 0 ? estimatedPriceTtrims : 0);
    }

    if(product === 'Baffle'){
      resultsTable.push(    
        ['2', 'Baffle Bottom', 'Black', `${result.panelCount} Panels`, productsConfigInMM[product].perPcSqFt, '50', `Rs.${result.bottomShadeEstimatedPrice.toFixed(2)}`]
      );
    }
  
    if (product !== 'Baffle'){
      resultsTable.push(
        ['2', `${jTrimsName}`, `J-Trims ${selectedShade.name}`, `${result.jTrims} ${jTrimsName}`, productsConfigInMM[product].JTrimperPcSqFt, selectedShade.jTrimPrice, `Rs.${estimatedPriceJtrims.toFixed(2)}`]
      );
    }

    if(product === 'Baffle'){
      resultsTable.push(
        ['3', 'Baffle Finishing Cap', 'Black', `${result.BaffleCapCount} Baffle Cap`, '-', selectedShade.finishingCapPrice, `Rs.${result.baffleCapPriceCalculation.toFixed(2)}`]
      );
    }


  if(product !== 'Baffle' || product === 'Soffit' || product === 'Louvers' || product === 'Duo' || product === 'Norma' || product === 'Streta'){  
    if (result.tTrims > 0) {
        resultsTable.push(['3', `${tTrimsName}`, `T-Trims ${selectedShade.name}`, `${result.tTrims} ${tTrimsName}`, productsConfigInMM[product].TTrimperPcSqFt, selectedShade.TtrimPrice, `Rs.${estimatedPriceTtrims.toFixed(2)}`]);
    }
  }

    doc.autoTable({
      startY: startYRequirements + 12,
      head: [['SR. NO', 'PRODUCT', 'SHADE', 'QUANTITY', 'SIZE PER PC / SQ FT', 'MRP PER PC / SQ FT', 'TOTAL ESTIMATION']],
      body: resultsTable,
      theme: 'striped',
      styles: {
          fillColor: [255, 255, 255],
          lineColor: [114, 114, 114], // Set border color for the cells
          lineWidth: 0.3 // Set border width for the cells
      },
      headStyles: {
          fillColor: [114, 114, 114], 
          textColor: [255, 255, 255],
          lineColor: [255, 255, 255], // Border color for header cells
          lineWidth: 0.3 // Border width for header cells
      },
      columnStyles: {
          0: { cellWidth: 'auto', halign: 'center' }, // SR. NO column
          1: { cellWidth: 'auto', halign: 'left' },   // PRODUCT column
          2: { cellWidth: 'auto', halign: 'left' },   // SHADE column
          3: { cellWidth: 'auto', halign: 'center' }, // QUANTITY column
          4: { cellWidth: 'auto', halign: 'center' }, // SIZE column
          5: { cellWidth: 'auto', halign: 'center' }, // MRP column
          6: { cellWidth: 'auto', halign: 'right' },  // TOTAL ESTIMATION column
      },
      
  });

    const afterTableY = doc.previousAutoTable.finalY + 5; // Adjusting the space below the table
    totalEstimatedCost = estimatedPricePanels 
                          + (product !== 'Baffle' ? estimatedPriceJtrims : 0) + (product === 'Baffle' ? result.bottomShadeEstimatedPrice : 0)
                          + (product !== 'Baffle' && result.tTrims > 0 ? estimatedPriceTtrims : 0) + (product === 'Baffle' ? result.baffleCapPriceCalculation : 0);
    
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text('Installation Cost:              As Actuals', 130, afterTableY);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text(`TOTAL ESTIMATED COST (INCL. GST):          Rs.${totalEstimatedCost.toFixed(2)}`, 95, afterTableY + 7);

    // Add "Terms & Conditions" heading
    const termsStartY = afterTableY + 30; // Provide additional space before the Terms & Conditions
    const termsTextY = termsStartY + 10;
    // Calculate the height of the text block (assuming 3 lines of text)
    const termsTextHeight = 15; // Adjust this value if the text is longer

    // Add background color for the "Terms & Conditions" section, including the heading
    doc.setFillColor(245, 245, 245); // Light grey background color
    doc.rect(10, termsStartY - 7, pageWidth - 20, termsTextHeight + 45, 'F'); // Adjust the rectangle size according to the content

    // Set font for the terms and conditions text
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.setTextColor(81, 81, 81); // Set text color

    // Draw the red line to the left of the "Terms & Conditions" heading
    doc.setDrawColor(197, 22, 28); // Set the color to red
    doc.setLineWidth(1.5); // Set the width of the line
    doc.line(12, termsStartY + 1 - 4, 12, termsStartY + 4); // Draw the vertical line aligned with the text

    //Terms & conditions heading
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    doc.setTextColor(81, 81, 81); // Dark slate blue color for heading
    doc.text('TERMS & CONDITIONS', 15, termsStartY + 2);

     // Define the terms text with bold numbers
     const terms = [
      { number: '1. ', text: 'This quotation does not include installation, transportation, or any other costs involved. It only accounts for PARÉ Materials required.' },
      { number: '2. ', text: 'The quantities mentioned are strictly indicative, please cross-check the sizing with your contractor once before placing an order.' },
      { number: '3. ', text: 'The rates mentioned are MRP only. This quotation does not account for any special pricing/discount, if applicable.' }
  ];

  let currentY = termsTextY;

  terms.forEach(item => {
      
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text(item.number, 15, currentY); // Bold the number
      doc.setFont('helvetica', 'normal');
      

      const splitText = doc.splitTextToSize(item.text, pageWidth - 30);
      doc.text(splitText, 20, currentY); // Indent the text
      currentY += splitText.length * doc.getTextDimensions(splitText).h + 1.5; // Adjust line height
  });

  // Save or preview the PDF as needed
  doc.save('Quotation.pdf');
};

useEffect(() => {
  if (generatePDF) {
    handleExportToPDF();
    setGeneratePDF(false); // Reset the trigger
  }
}, [generatePDF, result]); // Run this effect when `generatePDF` or `result` changes

const handleGenerateAndExport = async () => {
  // Perform calculations
  const calculationSuccess = handleCalculate();
  
  // If calculation was successful, proceed to generate the PDF
  if (calculationSuccess) {
    setGeneratePDF(true);
  }
};

useEffect(() => {
  if (generatePDF) {
    handleExportToPDF();
    sendDataToGoogleSheets();  // Call sendDataToGoogleSheets immediately after PDF generation
    setGeneratePDF(false);  // Reset the trigger after actions are performed
  }
}, [generatePDF, result]); // Ensure this effect runs when `generatePDF` or `result` changes    


  // Function to send data to Google Sheets via Google Apps Script Web App
  const sendDataToGoogleSheets = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('phoneNumber', phoneNumber);
    formData.append('product', product);
    formData.append('length', `${originalLength} ${unit}`);
    formData.append('breadth', `${originalBreadth} ${unit}`);
    formData.append('orientation', orientation);
    formData.append('selectedShade', selectedShade ? selectedShade.name : '');
    formData.append('selectedCategory', selectedCategory ? selectedCategory : 'N/A');
    formData.append('baffleSpacing', product === 'Baffle' ? baffleSpacing : 'N/A');

    // Append the calculated quantities
    formData.append('panelCount', result.panelCount); // Number of panels
    formData.append('jTrims', result.jTrims); // Number of J-Trims
    formData.append('tTrims', result.tTrims); // Number of T-Trims
    formData.append('BaffleCapCount', product === 'Baffle' ? result.BaffleCapCount : 'N/A'); // Number of Baffle Caps
  
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxYVe5zW3oDdj0u9Dzs1LEaz71Gv_z7V7DiQG8vsJtJlKD08IR9GYxrP5qOsw2laU7f/exec', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        console.log('Data sent to Google Sheets successfully!');
      } else {
        console.error('Failed to send data to Google Sheets.');
      }
    } catch (error) {
      console.error('Error sending data to Google Sheets:', error);
    }
  };

const handleShadeChange = (shade) => {
  if (!shade) {
    // Handle case when no shade is selected
    setSelectedShade(null);
    setResult(null);
    return;
  }
  const perPcSqFt = productsConfigInMM[product].perPcSqFt;
  const totalSqFt = panelCount * perPcSqFt;
  const estimatedPrice = totalSqFt * shade.price;

  setEstimatedPricePanels(estimatedPrice);
  setSelectedShade(shade);
};
    
    
    // if(product === 'Linea' || product === 'Pyramid'|| product === 'Arch' || product === 'Dome' || product === 'Prism' || product === 'Stripes' || product === 'Unic' ){
    //   console.log("Product Pricing panel Count: " +panelCountForPrice )
    //   const perPcSqFt = productsConfigInMM[product].perPcSqFt;
    //   console.log("Per Sq. Feet Value: " +perPcSqFt)
    //   const totalSqFt = panelCountForPrice * perPcSqFt;
    //   console.log("Total Square. Feet Value: " +totalSqFt)
    //   const estimatedPrice = totalSqFt * shade.price; 
    //   console.log("Estimate Price: "+estimatedPrice)
    //   setEstimatedPricePanels(estimatedPrice);
    //   setSelectedShade(shade);
    // }else{
      // console.log("Product Pricing panel Count: " +panelCount )
      // const perPcSqFt = productsConfigInMM[product].perPcSqFt;
      // // console.log("Per Sq. Feet Value: " +perPcSqFt)
      // const totalSqFt = panelCount * perPcSqFt;
      // // console.log("Total Square. Feet Value: " +totalSqFt)
      // const estimatedPrice = totalSqFt * shade.price;
      // // console.log("Estimate Price: "+estimatedPrice)
      // setEstimatedPricePanels(estimatedPrice);
      // setSelectedShade(shade);
  // }



  // const handleGetQuotation = () => {
  //   // Handle the calculation of the estimated price when "Get Your Quotation" is clicked
  //   const perPcSqFt = productsConfigInMM[product].perPcSqFt;
  //   const totalSqFt = panelCount * perPcSqFt;
  //   const estimatedPrice = totalSqFt * selectedShade.price;
  //   setEstimatedPrice(estimatedPrice);
  // };

   

  const companyLogo = `${process.env.PUBLIC_URL}/assets/logo.png`;
  const horizontalImage = `${process.env.PUBLIC_URL}/assets/horizontal-orientation.png`;
  const verticalImage = `${process.env.PUBLIC_URL}/assets/vertical-orientation.png`;

  // Handle changes from DimensionsInput
  // const handleLengthChange = (newLength) => {
  //   setLength(newLength);
  // };

  // const handleBreadthChange = (newBreadth) => {
  //   setBreadth(newBreadth);
  // };

  
  



  return (
    <div className="calculator-container">
      <div className="calculator-heading">
        <h1>PARÉ Quotation Generator</h1>
      </div>

      
      <div className="product-calculator-section">
      <div className="product-section-content">
        <div className="customer-details-row">
          <div className="customer-detail">
            <label>Name<span style={{ color: '#BE1E2D' }}>*</span>:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Full Name"
            />
          </div>
          <div className="customer-detail">
            <label>Phone Number<span style={{ color: '#BE1E2D' }}>*</span>:</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => {
              setPhoneNumber(e.target.value);
              
              }}
              placeholder="Phone Number"
            />
            
          </div>
          <div className="customer-detail">
              <label>State<span style={{ color: '#BE1E2D' }}>*</span>:</label>
              <select
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                <option value="">Select State</option>
                {indianStates.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          <div className="customer-detail">
            <label>City<span style={{ color: '#BE1E2D' }}>*</span>:</label>
            <input
                type="text"
                value={city}
                onChange={(e) => {
                setCity(e.target.value);}}
                placeholder="City"
                />
          </div>
          </div>
          <label>Product<span style={{ color: '#BE1E2D' }}>*</span>:</label>
          <select value={product} onChange={handleProductChange}>
            <option value="">Select Product</option>
            {Object.keys(productsConfig).map((key) => (
              <option key={key} value={key}>
                {productsConfig[key].name}
              </option>
            ))}
          </select>

           {/* Conditionally render BaffleOptions if the product is Baffle */}
          {product === 'Baffle' && (
              <BaffleOptions
                selectedCategory={selectedCategory}
                onSelectCategory={setSelectedCategory}
                selectedShade={selectedShade}
                onSelectShade={setSelectedShade}
                // selectedBottomShade={selectedBottomShade}
                // onSelectBottomShade={setSelectedBottomShade}
              />
            )}

          {product !== 'Baffle' && (
          <ShadesDropdown product={product} selectedShade={selectedShade} onSelectShade={handleShadeChange}/>
          )}
          {/* <FeetInchesToMMConverter/> */}
        </div>
      </div>

      <div className="calculator-section">
        {orientation && (
          <div className='left-side-section-content'>
            {/* <p className='width-text'>Width: {DisplayLengthInFeet}ft.</p> */}
            <div className="orientation-image-container">
            
              {/* <div className="vertical-length-text">
                <p>Length: {DisplayBreadthInFeet} ft.</p>
              </div> */}
            
              <img
                src={orientation === 'Horizontal' ? horizontalImage : verticalImage}
                alt={`${orientation} orientation`}
                style={{ width: '100%', height: 'auto' }}
              />
              
            </div>
            <div className="dynamic-text">
              <p>Width = {originalLength} {unit}.</p>
              <p>Length = {originalBreadth} {unit}.</p>
              <p>Standard Panel Size = {selectedProductDimensions.panelLength}  X  {selectedProductDimensions.panelBreadth} mm</p>
            </div>
          </div>
        )}
        <div className="right-side-section-content">

        {/* <OrientationSwitch
        orientation={orientation}
        setOrientation={setOrientation}
        /> */}

        <div className="orientation-container">
          <label className="orientation-label">Orientation<span style={{ color: '#BE1E2D' }}>*</span>:</label>
          <div className="orientation-radio-group">
            <label className="radio-label">
              <input type="radio" value="Horizontal" checked={orientation === 'Horizontal'} onChange={(e) => setOrientation(e.target.value)} />
              Horizontal
            </label>
            <label className="radio-label">
              <input type="radio" value="Vertical" checked={orientation === 'Vertical'} onChange={(e) => setOrientation(e.target.value)} />
              Vertical
            </label>
          </div>
        </div>
        
        <DimensionsInput
        product={product}
        length={length} 
        breadth={breadth} 
        onLengthChange={setLength}
        onBreadthChange={setBreadth}
        setUnit={setUnit}  // Pass the unit state down to DimensionsInput
        setOriginalLength={setOriginalLength} // Pass setOriginalLength to receive the original length
        setOriginalBreadth={setOriginalBreadth}
        
      />
          
          
          {product === 'Baffle' && (
            <label>
              Center To Center(CTC) Space(in mm)<span style={{ color: '#BE1E2D' }}>*</span>:
              <input
                type='number'
                value={baffleSpacing}
                onChange={(e) => setBaffleSpacing(e.target.value)}
              />
            </label>
          )}
          {showErrorMessage && (
          <p style={{ color: '#BE1E2D', fontStyle:'bold', marginTop: '10px', marginBottom: '5px' }}>
            **Please fill all the necessary fields.
          </p>
        )}
          <button onClick={handleGenerateAndExport}>Get Your Quotation</button>
          {/* <button onClick={handleExportToPDF} disabled={!result}>Export to PDF</button> */}
        </div>
      </div>

      {/* {result && (
        <div className="results-section">
          <h2>Results</h2>
          <table className="results-table">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Product</th>
                <th>Shade</th>
                <th>Orientation</th>
                <th>Width (mm)</th>
                <th>Length (mm)</th>
                <th>Required Quantity</th>
                <th>Per Pc / Sq Ft</th>
                <th>MRP / Sq Ft</th>
                <th>Total Estimation</th>
              </tr>
            </thead>
            <tbody> */}
              {/* Row for Panels */}
              {/* <tr>
                <td>1</td>
                <td>{productsConfigInMM[product].name}</td>
                <td>{selectedShade.name}</td>
                <td>{orientation}</td>
                <td>{length}</td>
                <td>{breadth}</td>
                <td>{result.panelCount} Panels</td>
                <td>{productsConfigInMM[product].perPcSqFt}</td>
                <td>{selectedShade.price}</td>
                <td>₹{estimatedPricePanels.toFixed(2)}</td>
              </tr>
              {product !== "Baffle" && (
                <> */}
                  {/* Row for J-Trims */}
                  {/* <tr>
                    <td>2</td>
                    <td>J-Trims</td>
                    <td>J-Trims {selectedShade.name}</td>
                    <td>{orientation}</td>
                    <td>{length}</td>
                    <td>{breadth}</td>
                    <td>{result.jTrims} J-Trims</td>
                    <td>{productsConfigInMM[product].JTrimperPcSqFt}</td>
                    <td>{selectedShade.jTrimPrice}</td>
                    <td>₹{estimatedPriceJtrims.toFixed(2)}</td>
                  </tr> */}

                  {/* Row for T-Trims, conditionally rendered if tTrims > 0 */}
                  {/* {result.tTrims > 0 && (
                    <tr>
                      <td>3</td>
                      <td>T-Trims</td>
                      <td>T-Trims {selectedShade.name}</td>
                      <td>{orientation}</td>
                      <td>{length}</td>
                      <td>{breadth}</td>
                      <td>{result.tTrims} T-Trims</td>
                      <td>{productsConfigInMM[product].TTrimperPcSqFt}</td>
                      <td>{selectedShade.TtrimPrice}</td>
                      <td>₹{estimatedPriceTtrims.toFixed(2)}</td>
                    </tr>
                  )}
                </>
              )} */}
              {/* <tr className="total-row">
                <td colSpan="9" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  Total Estimated Cost Incl. GST =
                </td>
                <td style={{ fontWeight: 'bold' }}>
                  ₹
                  {(
                    estimatedPricePanels +
                    (product !== "Baffle" ? estimatedPriceJtrims : 0) +
                    (product !== "Baffle" && result.tTrims > 0 ? estimatedPriceTtrims : 0)
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )} */}

      <div className="important-note">
        <p>Important Note: This Calculator is for indicative purposes only. Please recheck the exact calculation with your installer/contractor.</p>
      </div>
    </div>
  );
};

export default Calculator;