import React, { useState, useEffect } from 'react';
import "./UserDimensionsInput.css"

const DimensionsInput = ({ product, onLengthChange, onBreadthChange, setUnit, setOriginalLength, setOriginalBreadth }) => {
  const [localUnit, setLocalUnit] = useState('MM'); // Default unit is MM
  const [originalLength, setOriginalLengthState] = useState('');
  const [originalBreadth, setOriginalBreadthState] = useState('');
  const [convertedLength, setConvertedLength] = useState('');
  const [convertedBreadth, setConvertedBreadth] = useState('');

  // Conversion functions
  const mmToFeet = (mm) => (mm / 304.8).toFixed(2);
  const mmToInches = (mm) => (mm / 25.4).toFixed(2);
  const feetToMM = (feet) => (feet * 304.8).toFixed(2);
  const inchesToMM = (inches) => (inches * 25.4).toFixed(2);

  const handleUnitChange = (selectedUnit) => {
    setLocalUnit(selectedUnit);
    setUnit(selectedUnit); // Update the unit in Calculator.js

    if (selectedUnit === 'Feet') {
      // Convert MM to Feet
      setConvertedLength(mmToFeet(originalLength));
      setConvertedBreadth(mmToFeet(originalBreadth));
    } else if (selectedUnit === 'Inches') {
      // Convert MM to Inches
      setConvertedLength(mmToInches(originalLength));
      setConvertedBreadth(mmToInches(originalBreadth));
    } else {
      // Convert Feet/Inches to MM
      if (localUnit === 'Feet') {
        setConvertedLength(feetToMM(originalLength));
        setConvertedBreadth(feetToMM(originalBreadth));
      } else if (localUnit === 'Inches') {
        setConvertedLength(inchesToMM(originalLength));
        setConvertedBreadth(inchesToMM(originalBreadth));
      }
    }
  };

  // useEffect to handle product-wise conversion
  useEffect(() => {
    if (product === 'Soffit' || product === 'Duo') {
      if (localUnit === 'MM') {
        onLengthChange(mmToFeet(convertedLength));
        onBreadthChange(mmToFeet(convertedBreadth));
      } else if (localUnit === 'Inches') {
        onLengthChange(inchesToMM(convertedLength));
        onBreadthChange(inchesToMM(convertedBreadth));
      } else {
        onLengthChange(convertedLength);
        onBreadthChange(convertedBreadth);
      }
    } else {
      if (localUnit === 'Feet' || localUnit === 'Inches') {
        onLengthChange(feetToMM(convertedLength));
        onBreadthChange(feetToMM(convertedBreadth));
      } else {
        onLengthChange(convertedLength);
        onBreadthChange(convertedBreadth);
      }
    }
  }, [localUnit, convertedLength, convertedBreadth, product, onLengthChange, onBreadthChange]);

  const handleLengthChange = (e) => {
    const value = e.target.value;
    setOriginalLengthState(value);
    setOriginalLength(value); // Update original length in Calculator.js
    setConvertedLength(value);
  };

  const handleBreadthChange = (e) => {
    const value = e.target.value;
    setOriginalBreadthState(value);
    setOriginalBreadth(value); // Update original breadth in Calculator.js
    setConvertedBreadth(value);
  };

  return (
    <div className="dimensions-input-container">
      <div className="input-group">
        <label>Unit:</label>
        <select value={localUnit} onChange={(e) => handleUnitChange(e.target.value)}>
          <option value="MM">MM</option>
          <option value="Feet">Feet</option>
          <option value="Inches">Inches</option>
        </select>
      </div>
      <div className="input-row">
        <div className="input-group">
          <label>Width ({localUnit})<span style={{ color: '#BE1E2D' }}>*</span>:</label>
          <input
            type="number"
            value={convertedLength}
            onChange={handleLengthChange}
          />
        </div>
        <div className="input-group">
          <label>Length ({localUnit})<span style={{ color: '#BE1E2D' }}>*</span>:</label>
          <input
            type="number"
            value={convertedBreadth}
            onChange={handleBreadthChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DimensionsInput;
