export const BaffleCapHorizontal = ( breadth, Space) =>{
  // console.log("Breadth Baffle Cap: "+breadth)
  const horizontalCapCount = Math.ceil(breadth/ Space);
  // console.log("Horizontal Cap Count: "+horizontalCapCount)
  return horizontalCapCount * 2;
}

export const BaffleCapVertical = (length, Space) =>{
    // console.log("Length in Baffle Cap: " +length)
    const verticalCapCount = Math.ceil(length/Space);
    // console.log("Vertical Cap Count: "+verticalCapCount)
    return verticalCapCount * 2;
}