export const calculateStretaHorizontalPanelCount = (length, breadth, panelLength, panelBreadth) => {
    // console.log("Length: "+length);
    // console.log("Breadth: "+ breadth);
    // console.log("Panel Breadth: "+panelBreadth)
    if (length <= 3660) {
      if (length <= 1830 && breadth <= 1830) {
        return Math.ceil((length * breadth) / (panelLength * panelBreadth));
      }else {
        const initialPanelRequired = Math.ceil(breadth/panelBreadth);
        // console.log("Initial Panel Required: " +initialPanelRequired);
        const cuttingPanel = Math.floor(panelLength / length);
        // console.log("Cutting Panel: " +cuttingPanel);
        return Math.ceil(initialPanelRequired / cuttingPanel);
      }
    }else{
        const fullHorizontalItems = Math.floor(length / panelLength);
    // console.log("Full Horizontal Items: " +fullHorizontalItems);
    const fullVerticalItems = Math.ceil(breadth / panelBreadth);
    // console.log("Full Vertical Items: " +fullVerticalItems)
    const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
    
    // Handle the remaining length
    const remainingLength = Math.ceil(length % panelLength);
    // console.log("Remaining Length: " +remainingLength);
    let additionalpanelLengths = 0;
    if (remainingLength > 0 && remainingLength <= 1830) {
      const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
      const dividelenghtItems = Math.ceil(breadth/panelBreadth);
      additionalpanelLengths = Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
    }else if(remainingLength > 1830){
      additionalpanelLengths = Math.ceil(breadth/panelBreadth);
    }//else if(remainingLength === 5){ 
    //   additionalpanelLengths = breadth/2;
    // }
    // console.log("Additional Panel Length: " +additionalpanelLengths);
    const totalItems = fullpanelLengths + Number(additionalpanelLengths);
    return totalItems;
      }
    
}

export const calculateStretaVerticalPanelCount = (length, breadth, panelLength, panelBreadth) =>{
  
  if (length <= 1830 && breadth <= 1830) {
      return Math.ceil((length * breadth) / (panelLength * panelBreadth));
  }else{
    const fullHorizontalItems = Math.floor(length / panelLength);
    // console.log("Full Horizontal Items: "+fullHorizontalItems);
    const fullVerticalItems = Math.ceil(breadth / panelBreadth);
    // console.log("Full Vertical Items: " +fullVerticalItems)
    const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
    // Handle the remaining length
    const remainingLength = Math.ceil(length % panelLength);
    
    // console.log("Remaining Length: " +remainingLength);
    let additionalpanelLengths = 0;
    if (remainingLength > 0 && remainingLength <= 1830) {
      const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
      const dividelenghtItems = Math.ceil(breadth/panelBreadth);
      additionalpanelLengths = Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
    }else if(remainingLength > 1830){
      additionalpanelLengths = Math.ceil(breadth/panelBreadth);
    }//else if(remainingLength === 5){ 
    //   additionalpanelLengths = breadth/2;
    //} 
    // console.log("Additional Panel Length: " +additionalpanelLengths);
    const totalItems = fullpanelLengths + Number(additionalpanelLengths);
    return totalItems;
  }
}