export const JtrimsFormulaDura = (length, breadth) => {
    if(length <=12 ){
        if(length <= 6){
            var lengthTrims = 1;
        }else{
            var rows = 1
            lengthTrims = rows + rows;
        }
    }
    else if (length % 12 !== 0){
        const horizontalPanels = Math.ceil(length/12);
        rows = horizontalPanels - 1;
        lengthTrims = rows + rows;
        // console.log("Length Trims: " +lengthTrims);
    }else if(length % 12 === 0){
        const horizontalPanels = Math.ceil(length/12);
        lengthTrims = horizontalPanels + horizontalPanels;
    }

    //console.log("Lenght Trims:" + lengthTrims)
    
    if(breadth<=12){
        if(breadth<=6){
            var breadthTrims = 1;
        }else{
            var columns = 1
            breadthTrims = columns + columns;
        }    
    }
    else if(breadth % 12 !== 0){
        const verticalPanels = Math.ceil(breadth/12);
        columns = verticalPanels - 1;
        breadthTrims =  columns + columns;
        // console.log("Breadth Trims: " +breadthTrims);
    }else if(breadth % 12 === 0){
        const verticalPanels = Math.ceil(breadth/12);
        breadthTrims =  verticalPanels + verticalPanels;
    }
    //console.log("Breadth Trims:" + breadthTrims)

    if(length <=12){
        var finalRemainigLengthTrims = 0; 
    }else{
        const remainingLengthTrims = length % 12;
        finalRemainigLengthTrims = remainingLengthTrims + remainingLengthTrims;
        //console.log("final Remaining Length Trims: " +finalRemainigLengthTrims);
    }

    if(breadth <=12){
        var finalRemainigBreadthTrims = 0;
    }else{
        const remainingBreadthTrims = breadth % 12;
        finalRemainigBreadthTrims = remainingBreadthTrims + remainingBreadthTrims;
        //console.log("final Remaining Breadth Trims: " +finalRemainigBreadthTrims);
    }

    const addingRemaining = finalRemainigLengthTrims + finalRemainigBreadthTrims;
    const calculateTotalRemaining = Math.ceil(addingRemaining/12);
    //console.log("Total Remaining Trims: " + calculateTotalRemaining);

    const totalJTrims = lengthTrims + breadthTrims + calculateTotalRemaining;
    
    return totalJTrims; 
}


//Ttrim Horizontal Formula
export const TtrimsFormulaDura = (length, breadth, panelLength) => {
    // console.log("Length Of Room(T-Trims): " + length)
    // console.log("Breadth of Room(T-Trims): "+ breadth)
    // console.log("T-Trims Panel Length: " +panelLength)
    const horizontalPanels =  Math.ceil(length/panelLength);
    const rows = horizontalPanels - 1;
    const TtrimsCalculation = Math.floor(breadth / 12) * rows;
    
    const findingRemaining = breadth % 12;
    if(findingRemaining > 6){
        var finalRemaining = rows;
    }else{
        const cuttingTrims = Math.floor(12/findingRemaining)
        // const findingRemaining2 = findingRemaining * rows;
        finalRemaining = Math.ceil(rows/cuttingTrims);
    }
    const totalTTrims = TtrimsCalculation + finalRemaining;
    return totalTTrims;
}

//Ttrim Vertical Formula
export const TtrimsVerticalFormulaDura = (length, breadth, panelLength) => {
    // console.log("T-Trims Length: " +length)
    // console.log("T-Trims Breadth: " +breadth)
    // console.log("T-Trims Panel Length: " +panelLength)
    const horizontalPanels =  Math.ceil(breadth/panelLength);
    // console.log("T-Trims Horizontal Panels: " +horizontalPanels)
    const rows = horizontalPanels - 1;
    // console.log("T-Trims Rows: " +rows);
    const TtrimsCalculation = Math.floor(length / 12) * rows;
    // console.log("T-Trims TtrimsCalculation: "+ TtrimsCalculation);
    
    const findingRemaining = length % 12;
    if(findingRemaining > 6){
        var finalRemaining = rows;
    }else{
        const cuttingTrims = Math.floor(12/findingRemaining)
        // console.log("Cutting T Trims:" +cuttingTrims)
        // const findingRemaining2 = findingRemaining * rows;
        finalRemaining = Math.ceil(rows/cuttingTrims);
    }
    
    const totalTTrims = TtrimsCalculation + finalRemaining;
    return totalTTrims;
}