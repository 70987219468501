import React, { useState } from 'react';
import './FeetInchesToMMConverter.css'; // Ensure the path is correct

const FeetInchesToMMConverter = () => {
  const [feet, setFeet] = useState('0');
  const [inches, setInches] = useState('0');
  const [mm, setMm] = useState('');
  const [copied, setCopied] = useState(false);

  const handleConvert = () => {
    const totalInches = parseFloat(feet) * 12 + parseFloat(inches);
    const mmValue = totalInches * 25.4;
    setMm(mmValue.toFixed(3));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(mm);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleFocus = (e) => {
    if (e.target.value === '0') {
      e.target.value = '';
    }
  };

  const handleBlur = (e, setter) => {
    if (e.target.value === '') {
      setter('0');
    }
  };

  return (
    <div className="converter-container">
      <h2>Convert Your Size Units to mm:</h2>
      <div className="main-container">
        <div className="input-label-group">
          <div className="input-group">
            <input
              className="input-feet"
              type="number"
              placeholder="Feet"
              value={feet}
              onChange={(e) => setFeet(e.target.value)}
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, setFeet)}
            />
            <span className="unit-label">Ft.</span>
          </div>
          <div className="input-group">
            <input
              className="input-inches"
              type="number"
              placeholder="Inches"
              value={inches}
              onChange={(e) => setInches(e.target.value)}
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, setInches)}
            />
            <span className="unit-label">In.</span>
          </div>
          <div className="output-group">
            <input className="output" type="text" value={mm} readOnly />
            <span className="copy-icon" onClick={handleCopy}>
              &#x1f4cb;
            </span>
            {copied && <span className="copied-text">Copied!</span>}
          </div>
        </div>

        <button className="convert-button" onClick={handleConvert}>
          Convert
        </button>
      </div>
    </div>
  );
};

export default FeetInchesToMMConverter;
