export const HorizontalWastageOfPanel = (length, breadth, panelLength) => {
  // console.log("Length Of Room : " + length);
  // console.log("Breadth of Room : " + breadth);
  
  // panelLength = panelLength/304.8;
  // console.log("Panel Length : " + panelLength);

  const remainingLength = Math.ceil(length % panelLength);
  // console.log("Remaining Length : " + remainingLength);

  if (remainingLength === 0) {
    return 0; // No wastage
  }

  const wastagePerPanel = Math.floor(panelLength % remainingLength);
  // console.log("Wastage Per Panel: " + wastagePerPanel);

  const panelNeededInLastrow = Math.ceil(breadth/remainingLength);
  // console.log("Panel Needed in Last Row : " + panelNeededInLastrow);

  const totalWastage = panelNeededInLastrow * wastagePerPanel;
  // console.log("Total Wastage: " + totalWastage);

  return totalWastage;
};

export const VerticalWastageOfPanel = (length, breadth, panelLength) => {
  const remainingLength = Math.ceil(breadth % panelLength);

  if (remainingLength === 0) {
    return 0; // No wastage
  }

  const wastagePerPanel = Math.floor(panelLength % remainingLength);
  // console.log("Wastage Per Panel: " + wastagePerPanel);

  const totalWastage = length * wastagePerPanel;
  // console.log("Total Wastage: " + totalWastage);
  return totalWastage;
};
